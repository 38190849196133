<template>
	<div>
		<CRow>
					<CButton size="sm" class="btn btn-link back-btn " 
					@click="backToTable()"><i class="fa fa-arrow-left"></i>Back</CButton>
			</CRow>
		<CCard class="p-3"> 
			<CCardHeader>
				
				<CRow style="color: #1352a1;">
					<CCol lg="6">
						<h5>Work Order/ #{{dataParams.wo_no}}</h5>
					</CCol>
					
					<CCol lg="6">
			      		<div style="text-align: right">  
							<CButton class="mr-1 btn btn-outline-primary" size="sm" @click="showHistory"><i class="fa fa-list"></i> History</CButton> 
							<CButton class="mr-1 btn btn-outline-primary" size="sm" @click="print"><i class="fa fa-print"></i> Print</CButton> 
							<CButton class="mr-1 btn btn-success" size="sm" @click="openApproveModal(dataParams)" v-if="dataParams.status == 'submitted'"><i class="fa fa-thumbs-o-up"></i> Approve</CButton> 
			      			<CButton class="mr-1 btn btn-secondary" size="sm" @click="openOnServiceModal(dataParams)" v-if="dataParams.status == 'approved' "><i class="fa fa-spinner"></i> On Service</CButton> 
			      			<CButton class="mr-1 btn btn-primary" size="sm" @click="openCompleteModal(dataParams)" v-if="dataParams.status == 'on service' "><i class="fa fa-check-circle"></i> Complete</CButton> 
							
							
			      			<!-- <CButton class="mr-1" color="btn btn-danger" size="sm" @click="backToTable()"><i class="fa fa-close"></i> Close</CButton> -->
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
	<CCardBody>
	<CCard class="card-view">
				<CRow class="p-2 mb-2 bg-secondary text-dark">
							<CCol lg="6">
							<strong style="font-size:12px"> Work Order Information</strong>
						</CCol>	
				</CRow>
					<CRow class="ml-2 mr-2">
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Asset Type</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.asset_type ? dataParams.asset_type : "N/A"}}</label>
                        	</div>
							
						</CCol>
						<CCol lg="3">
							<div class="form-group">
								<small class="" v-if="dataParams.asset_type == 'vehicle'"> Plate No.</small>
								<label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;"
										v-if="dataParams.asset_type == 'vehicle'" >{{dataParams.vehicle.plate_no }}</label>
                        	</div>
						
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <!-- <small class="">{{dataParams.asset_type == 'vehicle' ? 'Plate No.' : 'Asset No.'}}</small> -->
							 <!-- <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.asset_type == 'vehicle' ? dataParams.vehicle.plate_no : 
								  (dataParams.asset_type == 'equipment' ? dataParams.equipment.asset_no : dataParams.furnishing.asset_no) }}</label> -->
							<small class=""> Asset No.</small>
							 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">
							 	{{dataParams.asset_type == 'vehicle' ? dataParams.vehicle.asset_no : 
								  (dataParams.asset_type == 'equipment' ? dataParams.equipment.asset_no : dataParams.furnishing.asset_no) }}</label>

                        </div>
						</CCol>
						
						
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Status</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.status}}</label>
                        	</div>
							
						</CCol>  
					
					</CRow>

					<CRow class="ml-2 mr-2" style="margin-top:-10px;">
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">WO Type</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.wo_type ? dataParams.wo_type : "N/A"}}</label>
                        	</div>
						</CCol> 
						<CCol lg="3" v-if="dataParams.asset_type == 'vehicle'">
							<div class="form-group">
                         	 <small class="">Odometer</small>
                         	 <label class="form-text mt-0" style="font-size: 13px; font-weight: 600;">
								  {{dataParams.odometer_reading ? dataParams.odometer_reading.reading : "0"}} km</label>
								
								<!-- {{dataParams.odometer_reading.reading && dataParams.odometer_reading.reading!= "" ? dataParams.odometer_reading.reading : "0"}} km </label> -->
                        	</div>
							
						</CCol>
					</CRow>
					<CRow class="ml-2 mr-2" style="margin-top:-10px;"> 
						
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Date Needed</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.date_needed ? moment.unix(dataParams.date_needed).format('MMMM DD, YYYY') : "N/A"}}</label>
                        	</div>
							
						</CCol>  
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Requested by</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.requested_by? dataParams.requester.name : dataParams.requester.name}}</label>
                        	</div>
						
						</CCol>
					</CRow>
					<CRow class="ml-2 mr-2" style="margin-top:-10px;"> 
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Start Date</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.start_date ? moment.unix(dataParams.start_date).format('MMMM DD, YYYY') : "-"}}</label>
                        	</div> 
						</CCol>
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Finish Date</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.finish_date ? moment.unix(dataParams.finish_date).format('MMMM DD, YYYY') : "-"}}</label>
                        	</div> 
						</CCol> 
					</CRow>
					<CRow class="ml-2 mr-2" style="margin-top:-10px;"> 
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Location</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.location ? dataParams.location : "-"}}</label>
                        	</div>
						
						</CCol>  
						
					</CRow>
					<CRow class="ml-2 mr-2" style="margin-top:-10px;"> 
						<CCol lg="6">
							<div class="form-group">
                         	 <small class="">Dept/Proj</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.dept ? dataParams.dept.customer_name : "N/A"}}</label>
                        	</div>
							<!-- Dept / Proj: <strong>{{dataParams.dept ? dataParams.dept.customer_name : "N/A"}}</label> -->
						</CCol>  
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Depot</small>
                         	 <strong class="form-text mt-0">{{dataParams.depot ? dataParams.depot.setting_name : "-"}}</strong>
                        	</div>
						</CCol>
					</CRow>
					
						
					
					<CRow class="ml-2 mr-2" style="margin-top:-10px;">
						
						
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Assigned to</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.assigned_to}}</label>
                        	</div>
						
						</CCol>  
						<CCol lg="9" v-if="dataParams.assigned_to == 'subcon'">
							<div class="form-group">
                         	 <small class="">Company</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.vendor_id ? dataParams.vendor.vendor_name : "N/A"}}</label>
                        	</div>
							
						</CCol>  
						
					</CRow> 
					<CRow class="ml-2 mr-2" style="margin-top:-10px;">
						
						<CCol lg="12">
							<div class="form-group">
                         	 <small class="">Service Summary</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.service_summary ? dataParams.service_summary : "-"}}</label>
                        	</div>
							<!-- Requested By: <strong>{{dataParams.requested_by? dataParams.requester.name : dataParams.requester.name}}</label> -->
						</CCol>

						
						 
					</CRow> 
	</CCard>
	<CCard class="card-view">
				<CRow class="p-2 mb-1 bg-secondary text-dark">
						<CCol lg="12">
							<strong style="font-size:12px"> Labor Request</strong>
						</CCol>
						<!-- <CCol lg="3" class="text-right">
						<label class="d-flex justify-content-between " style="font-weight:600">
						Labor Total:
						<strong>{{total_labor_request |  number_format('currency')}} </label>
						</label>
						</CCol> -->
					</CRow>
					<br>
					
					<CRow class="mb-1">
		        		<CCol lg="12">
		        			<CDataTable
							
						      	:items="work_order_labor_request"
						      	:fields="[
								{
										key: 'service_type', 
										label: 'Service Type'
									},
								  {
									
										key: 'description', 
										label: 'Description'
									},
									{
										key: 'qty', 
										label: 'Qty'
									},
									{
										key: 'unit', 
										label: 'Cost'
									},
									{
										key: 'total', 
										label: 'Total'
									}
									
									
								]"
								hover
								small
								outlined
								custom
								>
								<template #service_type="{item}">
										 <td style="text-transform:capitalize;">
										 	{{item.service_type}}
				 						 </td>
								</template>
						    </CDataTable> 
							
		        		</CCol> 
		        	</CRow> 
					
				<br>
					<CRow class="p-2 mb-1 bg-secondary text-dark">
						<CCol lg="9">
							<strong style="font-size:12px"> Part Request</strong>
						</CCol>
						
					</CRow>
					<br>
					<CRow class="mb-1">
		        		<CCol lg="12">
		        			<CDataTable
							
						      	:items="work_order_part_request"
						      	:fields="[
								  {
										key: 'part_inventory_id_label', 
										label: 'Description'
									},
									{
										key: 'qty', 
										label: 'Qty'
									},
									{
										key: 'unit', 
										label: 'Unit Cost'
									},
									{
										key: 'total', 
										label: 'Total'
									}, 
									
									
								]"
								hover
								small
								outlined
								
						    >   
						    </CDataTable>
							
		        		</CCol> 
		        	</CRow>  
					<CRow>
			<CCol lg="8" class="text-right"></CCol>
			<CCol lg="4" class="text-left">
				<h6>Cost Summary</h6>
			</CCol>
		</CRow>
		<CRow>
			<CCol lg="8" class="text-right"></CCol>
			<CCol lg="4" class="text-right">
				<ul class="list-group list-group-flush">
					<li class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:14px">
						Labor
						<strong>{{total_labor_request | number_format(null, 2) }}</strong>
					</li>
					<li class="list-group-item d-flex justify-content-between align-items-center py-1" style="font-size:14px">
						Part
						<strong>{{total_part_request | number_format(null, 2) }}</strong>
					</li>
					
					<li class="list-group-item d-flex justify-content-between align-items-center" style="font-weight:700; font-size:18px">
						TOTAL
						<strong> {{computeTotal | number_format(null, 2)}} </strong>
					</li>
					
				</ul>
				
			</CCol>
		</CRow>
	</CCard>
	<CCard class="card-view">
				<CRow class="p-2 mb-2 bg-secondary text-dark">
							<CCol lg="6">
							<strong style="font-size:12px">  References </strong>
						</CCol>	
				</CRow>
				<CRow class="ml-3 mr-3">
					
					<CCol lg="12">
						<div class="form-group">
						  <small class="">Remarks</small>
						  <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.remarks ? dataParams.remarks : "-"}}</label>
						</div>
						
						
					</CCol>
				</CRow>
					
					<CRow class="ml-3 mr-3" style="margin-top:-10px;">
				
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">SRF No.</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.srf_no ? dataParams.srf_no : "-"}}</label>
                        	</div>
							
						</CCol> 
					
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">P.O No.</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.po_no ? dataParams.po_no : "-"}}</label>
                        	</div>
							<!-- PO No.: <strong>{{dataParams.po_no ? dataParams.po_no : "N/A"}}</label> -->
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class=""> Inv. No.</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.inv_no ? dataParams.inv_no : "-"}}</label>
                        	</div>
							<!-- Inv No.: <strong>{{dataParams.inv_no ? dataParams.inv_no : "N/A"}}</label> -->
						</CCol> 
					</CRow>
					<CRow class="ml-3 mr-3" style="margin-top:-10px;">
					
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Performed By</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.performed_by ? dataParams.performed_by : "-"}}</label>
                        	</div>
							
							<!-- Remarks: <strong>{{dataParams.remarks ? dataParams.remarks : "N/A"}}</label> -->
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Checked By</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.checked_by ? dataParams.checked_by : "-"}}</label>
                        	</div>
							<!-- SRF No.: <strong>{{dataParams.srf_no ? dataParams.srf_no : "N/A"}}</label> -->
						</CCol> 
					<!-- </CRow>
					<CRow class="ml-3 mr-3 mt-2">
						 -->
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Confirmed By</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.confirmed_by ? dataParams.confirmed_by : "-"}}</label>
                        	</div>
							<!-- PO No.: <strong>{{dataParams.po_no ? dataParams.po_no : "N/A"}}</label> -->
						</CCol>
						<CCol lg="3">
							<div class="form-group">
                         	 <small class="">Noted By</small>
                         	 <label class="form-text mt-0 text-capitalize" style="font-size: 13px; font-weight: 600;">{{dataParams.noted_by ? dataParams.noted_by : "-"}}</label>
                        	</div>
							<!-- Inv No.: <strong>{{dataParams.inv_no ? dataParams.inv_no : "N/A"}}</label> -->
						</CCol> 
					</CRow>
				<!-- </CCard> -->

	</CCard>
	</CCardBody> 
			  
		</CCard>  
		<CModal title="Work Order History" :show.sync="historyModal" size="lg" color="info">
            
			<CRow class="mb-1">
        		<CCol lg="12">
        			<CDataTable
				      	:items="historyList"
				      	:fields="[
						  {
								key: 'description', 
								label: 'Description'
							},
							{
								key: 'user_label', 
								label: 'User'
							},
							{
								key: 'wo_date_created',
								label: 'Transaction Date'
							},
							{
								key: 'remarks_label', 
								label: 'Remarks'
							}
						]"
						hover
						small
						
				    >   
				    	<template #description="{item}">
					        <td style="padding: 5px;">
					        	<span>{{item.description}}</span><br/>
					        	<small><span>{{moment.unix(item.wo_date).format('MMMM DD, YYYY hh:mm A')}}</span></small>

					        </td>
				      	</template>
				    	
				    </CDataTable>  
        		</CCol> 
        	</CRow>   	
				
            <!-- <div slot="footer" class="w-100">
            	<CButton @click="historyModal = false" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right">
                    Close
                </CButton>
               
                
            </div>  -->
           	<div slot="footer" class="w-100"></div>
        </CModal> 
		<ApproveModal :details="dataParams" @showModal="approveModal = $event; getData();" :showModal="approveModal" />
        <OnServiceModal :details="dataParams" @showModal="onserviceModal = $event; getData();" :showModal="onserviceModal" />
        <CompleteModal :details="dataParams" @showModal="completeModal = $event; getData();" :showModal="completeModal" /> 
		<Print :dataParams="dataParams" :workOrderLaborRequest="work_order_labor_request" :workOrderPartRequest="work_order_part_request" :totalPartRequest="total_part_request" :totalLaborRequest="total_labor_request"/>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../style.css';
import moment from 'moment'; 
import ApproveModal from './status_modal/approve';
import OnServiceModal from './status_modal/onservice';
import CompleteModal from './status_modal/complete';
import Print from './print';

export default {
	mounted(){  

		this.getData(); 
		this.getLaborRequest();
		this.getPartRequest();
		
	},
	computed:{
		computeTotal (){
			let total = 0;
			total += this.total_labor_request ? parseFloat(this.total_labor_request) : 0;
			total += this.total_part_request ? parseFloat(this.total_part_request) : 0;
			return total;
			},

	},
	data(){
		return{    
			dataList: [],    
			historyList: [],
			historyModal: false,
			total_labor_request: 0.00,
			total_part_request: 0.00, 
			approveModal: false,
			onserviceModal: false,
			completeModal: false, 
			dataParams: {
			
				equipment_id: "",
				vehicle_id: "",
				furnishing_id:"",
				requested_by:"",
				subcon_id:"",
				asset_type:"vehicle",
				wo_type:"",
				location:"", 
				service_summary:"",
				date_needed:"",
				remarks:"",
				srf_no:"",
				po_no:"",
				inv_no:"",
				status:"",
				wo_no:"",
				dept_id: "",
				assigned_to: "",
				vendor_id: "",
				
				vehicle: {
					plate_no: ""
				},
				requester: {
					name: ""
				}
			},
			work_order_labor_request: [ ],
			work_order_part_request: [ ],
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect, ApproveModal,OnServiceModal,CompleteModal, Print},
	methods: {  
		showHistory(){
			this.historyModal = true 
		},
		reload(){
			setTimeout(()=>{
				this.getData();
			},500) 
		},
		getData(){
			this.$showLoading(true)

			axios.get(config.api_path+"/workOrder/"+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;    
		    	this.historyList = this.dataParams.work_order_history.map((value, index)=>{
		    		value.user_label = value.user.firstname+" "+value.user.lastname;
		    		
		    		if(value.remarks && value.remarks != ""){
		    			value.remarks_label = value.remarks;
		    		}
		    		else{
		    			value.remarks_label = "-";
		    		}
		    		value.wo_date_created = moment(value.created_at).format('MMMM DD, YYYY h:mm:ss A')
		    		return value
		    	});
			})  
			.catch(err => {
			this.$showLoading(false)
		})   
		},   
		 openApproveModal(item){
	    	this.approveModal = true;
	    	this.selectedRow = item;
	    },

	    openOnServiceModal(item){
	    	this.onserviceModal = true;
	    	this.selectedRow = item;
	    },


	    openCompleteModal(item){
	    	this.completeModal = true;
	    	this.selectedRow = item;
	    }, 
	    backToTable(){
	    	this.$router.push('/main/work_order')
	    }, 

	    updateModal(item){
	    	this.$router.push('/main/update_booking/'+item.id)
	    },

	    viewDr(item){
	    	this.$router.push('/main/view_booking/'+item.id+"/dr")
	    },

	    getLaborRequest(){
			this.$showLoading(true)
			
			axios.get(config.api_path+"/workOrderLaborRequest?work_order_id="+this.$route.params.id)
			.then(response=>{  
				this.$showLoading(false)
				this.work_order_labor_request = response.data.data;   
				for(var i = 0; i < this.work_order_labor_request.length; i++){
					this.total_labor_request = parseFloat(this.total_labor_request) 
					this.total_labor_request += parseFloat(this.work_order_labor_request[i].total);
				}
			})
		}, 

		getPartRequest(){
			this.$showLoading(true)
			axios.get(config.api_path+"/workOrderPartRequest?work_order_id="+this.$route.params.id)
			
			.then(response=>{  
				this.$showLoading(false)
				this.work_order_part_request = response.data.data.map((value, index)=>{
					if(value.part_inventory_id){
						value.part_inventory_id_label = value.part_inv.part_name;
					}
					else{
						value.part_inventory_id_label = "N/A";
					}
					return value;
				});   
				for(var i = 0; i < this.work_order_part_request.length; i++){
					this.total_part_request = parseFloat(this.total_part_request) 
					this.total_part_request += parseFloat(this.work_order_part_request[i].total);
				}
				})  
			.catch(err => {
			this.$showLoading(false)
			})
			
		}, 

		print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('work_order_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    }, 

 	}
}
</script>
